body {
  margin: 0;
  font-family: "メイリオ", Meiryo, "游ゴシック", YuGothic, "Droid Sans",
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "ＭＳ ゴシック",
    sans-serif, FontAwesome;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* 安卓 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body .MuiButtonBase-root.MuiMenuItem-root {
  font-size: 1.285vw;
  font-weight: 700;
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
}
