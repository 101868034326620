@font-face {
  font-family: "Superstar M54";
  src: url("Superstar M54.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("NotoSansJP-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans JP Light";
  src: url("NotoSansJP-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans JP Regular";
  src: url("NotoSansJP-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Akshar";
  src: url("Akshar.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Compacta-BT";
  src: url("Compacta-BT.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Compacta Bd BT";
  src: url("COMPCTAB.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}
